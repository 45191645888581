import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi';
import { Link } from 'react-scroll';

const Home = () => {
  return (
    <div name='home' className="w-full h-screen py-8 bg-[#F7FBFC]">
      {/* Container */}
      <div className="max-w-[1000px] 7xl:max-w-[2500px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-[#5651e5] text-xl 7xl:text-5xl">Hi, my name is </p>
        <h1 className="text-4xl sm:text-5xl 7xl:text-8xl font-bold text-gray-800">Mike Sherman</h1>
        <h2 className="text-4xl sm:text-5xl 7xl:text-8xl font-bold text-gray-500">I'm a Full Stack Developer.</h2>
        <p className="text-gray-500 py-4 max-w-700px 7xl:text-4xl">I'm a full-stack developer with specialization in mobile devlopment and
          building responsive web applications.
        </p>
        <div>
          <Link to="work" offset={-140} smooth={true} duration={500} >
            <button className="7xl:text-4xl button-animation rounded-md 7xl:rounded-2xl duration-300 text-gray-700 border-2 border-gray-500 px-6 py-3 my-2 7xl:px-12 7xl:py-8 7xl:my-4 flex items-center hover:bg-[#5651e5] hover:text-white hover:border-[#5651e5]">View Work
              <span className="duration-300">
                <HiArrowNarrowRight className="ml-2" />
              </span>
            </button>
          </Link>
        </div>

      </div>
    </div>
  )
}

export default Home