import React from 'react'
import SocialBlogImg from '../assets/socialblog.png';
import TwitterCloneImg from '../assets/twitter_clone.png';
import weatherAppImg from '../assets/weatherApp.png';
import { GoGlobe } from 'react-icons/go';
import { BsGithub } from 'react-icons/bs';

const Work = () => {
    return (
        <div name='work' className="w-full h-full 7xl:h-screen pb-10 pt-5 bg-[#F7FBFC] text-gray-700">
            <div className="max-w-[1000px] 7xl:max-w-[2500px] mx-auto p-4 flex flex-col justify-center w-full h-full">
                <div className="pb-8">
                    <p className="text-4xl 7xl:text-8xl font-bold inline border-b-4 border-[#5651e5]">Work</p>
                    <p className="py-4 7xl:text-5xl 7xl:py-8">// Recent Projects </p>
                </div>

                {/* Container */}
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4 7xl:gap-8">
                    

                    {/* Grid item */}
                    <div className="py-2 7xl:py-4 shadow shadow-[#606469] bg-[#5651e5] rounded-lg 7xl:rounded-3xl">
                        <span className="text-xl 7xl:text-4xl font-bold text-white px-2 7xl:px-4 tracking-wider">
                            Social Blog (MERN)
                        </span>
                        <div>
                            <img className="object-cover p-2 7xl:p-4 rounded-xl" src={SocialBlogImg} alt="Social Blog MERN project" />
                        </div>

                        <div className="grid grid-cols-3 md:grid-cols-4 gap-2 7xl:gap-4 p-2 7xl:p-4 text-xs lg:text-sm 7xl:text-3xl border-y-2 text-gray-600">
                            <div className="text-center rounded-md px-2 py-1 font-bold bg-white">
                                MongoDB
                            </div>
                            <div className="text-center rounded-md px-2 py-1 font-bold bg-white">
                                Express JS
                            </div>
                            <div className="text-center rounded-md px-2 py-1 font-bold bg-white">
                                React JS
                            </div>
                            <div className="text-center rounded-md px-2 py-1 font-bold bg-white">
                                Node JS
                            </div>
                            <div className="text-center rounded-md px-2 py-1 font-bold bg-white">
                                Material-UI
                            </div>
                        </div>
                        <div className="pt-3 px-2 text-end flex flex-row justify-end gap-4 w-full">
                            <a className="hover:scale-110 duration-300" href="https://whichcoffeedev.com/posts">
                                <GoGlobe className="text-white w-8 h-8 7xl:w-[70px] 7xl:h-[70px]"  />
                            </a>
                            <a className="hover:scale-110 duration-300" href="https://github.com/mikeshermant/social_media_app">
                                <BsGithub className="text-white w-8 h-8 7xl:w-[70px] 7xl:h-[70px]" />
                            </a>
                        </div>

                    </div>

                    {/* Grid item */}
                    <div className="py-3 7xl:py-6 shadow shadow-[#606469] bg-[#5651e5] rounded-lg 7xl:rounded-3xl">
                        <span className="text-xl 7xl:text-4xl font-bold text-white px-2 7xl:px-4 tracking-wider">
                            Twitter Clone
                        </span>
                        <div>
                            <img className="object-cover p-2 7xl:p-4 rounded-xl" src={TwitterCloneImg} alt="Twitter Clone project" />
                        </div>

                        <div className="grid grid-cols-3 md:grid-cols-4 gap-2 7xl:gap-4 p-2 7xl:p-4 text-xs lg:text-sm 7xl:text-3xl border-y-2 text-gray-600">
                            <div className="text-center rounded-md px-2 py-1  font-bold text-gray-700 bg-white">
                                Next JS
                            </div>
                            <div className="text-center rounded-md px-2 py-1  font-bold text-gray-700 bg-white">
                                Sanity
                            </div>
                            <div className="text-center rounded-md px-2 py-1  font-bold text-gray-700 bg-white">
                                NextAuth
                            </div>
                            <div className="text-center rounded-md px-2 py-1  font-bold text-gray-700 bg-white">
                                Tailwind
                            </div>
                            <div className="text-center rounded-md px-2 py-1  font-bold text-gray-700 bg-white">
                                Typescript
                            </div>
                        </div>
                        <div className="pt-3 px-2 text-end flex flex-row justify-end gap-4 w-full">
                            <a className="hover:scale-110 duration-300" href="https://twitter-clone-psi-eight.vercel.app/">
                                <GoGlobe className="text-white w-8 h-8 7xl:w-[70px] 7xl:h-[70px]" />
                            </a>
                            <a className="hover:scale-110 duration-300" href="https://github.com/maliksheharyaar/twitter-clone">
                                <BsGithub className="text-white w-8 h-8 7xl:w-[70px] 7xl:h-[70px]" />
                            </a>
                        </div>

                    </div>

                    {/* Grid item */}
                    <div className="py-3 7xl:py-6 shadow shadow-[#606469] bg-[#5651e5] rounded-lg 7xl:rounded-3xl">
                        <span className="text-xl 7xl:text-4xl font-bold text-white px-2 7xl:px-4 tracking-wider">
                            PWA Weather App
                        </span>
                        <div>
                            <img className="object-cover p-2 7xl:p-4 rounded-xl" src={weatherAppImg} alt="PWA Weather App project" />
                        </div>

                        <div className="grid grid-cols-3 md:grid-cols-4 gap-2 7xl:gap-4 p-2 7xl:p-4 text-xs lg:text-sm 7xl:text-3xl border-y-2 text-gray-600">
                            <div className="text-center rounded-md px-2 py-1  font-bold text-gray-700 bg-white">
                                React JS
                            </div>
                        </div>
                        <div className="pt-3 px-2 text-end flex flex-row justify-end gap-4 w-full">
                            <a className="hover:scale-110 duration-300" href="https://weather-app-whichcoffeedev.netlify.app/">
                                <GoGlobe className="text-white w-8 h-8 7xl:w-[70px] 7xl:h-[70px]" />
                            </a>
                            <a className="hover:scale-110 duration-300" href="https://github.com/maliksheharyaar/pwa_react_weather_app">
                                <BsGithub className="text-white w-8 h-8 7xl:w-[70px] 7xl:h-[70px]" />
                            </a>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Work