import React from 'react'

const Contact = () => {
  return (
    <div name='contact' className="w-full h-screen pt-12 bg-[#F7FBFC] text-gray-700 flex justify-center items-center p-4">
        <form method='POST' action="https://getform.io/f/e37391f3-ef85-4890-a1c6-e14187ac3370" className="flex flex-col max-w-[600px] 7xl:max-w-[1600px] w-full 7xl:text-4xl">
            <div className="pb-8">
                <p className="text-4xl 7xl:text-8xl font-bold inline border-b-4 border-[#4a49da] text-gray-700">Contact</p>
                <p className="text-gray-700 7xl:text-4xl py-4 7xl:py-8">// Submit the form below or send me an email at <a className="underline" href="mailto:malik.s.talhat@gmail.com">mikesherman.t@gmail.com</a> </p>
                    
            </div>
            <input required className="bg-[#F7FBFC] border-2 border-gray-300 rounded-md p-2 7xl:p-4 " type="text" placeholder="Name" name="name" />
            <input required className="my-4 p-2 rounded-md bg-[#F7FBFC] border-2 border-gray-300 7xl:p-4 " type="email" placeholder="Email" name="email" />
            <textarea required className="bg-[#F7FBFC] border-2 border-gray-300 rounded-md p-2 7xl:p-4 " name="message" rows="10" placeholder="Message"></textarea>
            <button className="duration-300 rounded-md 7xl:rounded-2xl text-gray-700 border-2 border-gray-500 px-4 py-3 my-8  7xl:px-8 7xl:py-9 7xl:my-16 mx-auto flex items-center hover:bg-[#4a49da] hover:text-white hover:border-[#4a49da]">Let's get in touch</button>
        </form>
    </div>
  )
}

export default Contact