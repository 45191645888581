import React from 'react'

const About = () => {
  return (
    <div name='about' className="w-full h-screen 6xl:h-screen  bg-[#F7FBFC] text-gray-700">
          <div className="flex flex-col justify-center items-center w-full h-full">
              <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
                  <div className="sm:text-right pb-8 pl-4">
                      <p className="text-4xl  7xl:text-8xl font-bold inline border-b-4 border-[#5651e5]">About</p>
                  </div>
                  <div>

                  </div>
              </div>
              <div className="max-w-[1000px] 7xl:max-w-[2500px] w-full grid sm:grid-cols-2 gap-8 px-4 7xl:gap-10">
                  <div className="sm:text-right text-4xl 7xl:text-8xl font-bold">
                      <p>
                          Hi, I'm Mike, feel free to look around and lets get in touch if something interests you.
                      </p>
                  </div>
                  <div>
                      <p className="7xl:text-4xl 7xl:leading-[4rem]">
                          I graduated with Bachelor of Science in Computer Science
                          from Sheridan College with specialization in mobile development along with being passionate about building
                          software that is visually appealing and gets the work done with minimum effort. On the side I occupy myself
                          with building plastic model kits and occasionly add some weathering paint to give a "war ridden" look to the models. I also like real time strategy games like
                          Total War: Warhammer 3
                      </p>
                  </div>
              </div>

          </div>

    </div>
  )
}

export default About